import React from 'react'
import styled from 'styled-components'
import { Card } from '@pancakeswap-libs/uikit'

export const BodyWrapper = styled(Card)`
  position: relative;
  max-width: 491px;
  width: 100%;
  z-index: 5;
  background: #151515;
  box-shadow: 0 12px 124px rgba(0, 0, 0, .05);
  border-radius: 17px;
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>    
    {children}</BodyWrapper>
}
