import { createGlobalStyle } from 'styled-components'
import Stopbuck from '../stopbuck/Stopbuck.ttf';



const GlobalStyle = createGlobalStyle`



@font-face{
  font-family: 'Stopbuck';
  src: url('${Stopbuck}') format('truetype'); 
}


* {
  font-family: Stopbuck;
}

  body {
    // background-color: ${({ theme }) => theme.colors.background};
    background: url('/images/bg.png');
    background-size: cover;
    font-family: 'Stopbuck';
    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
